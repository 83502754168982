<template>
  <el-row class="warp">
    <el-col :span="24" class="warp-main" v-loading="loading" element-loading-text="拼命加载中">
      <!--工具条-->
      <el-form :inline="true" :model="searchForm" size="small">
        <el-form-item>
          <el-input v-model="searchForm.cnName" placeholder="请输入名称" @keyup.enter.native="handleSearch"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="searchForm.dictionaryId" placeholder="请输入编号" @keyup.enter.native="handleSearch"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleSearch">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleReset">重置</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="showAddEditDialog()">新增</el-button>
        </el-form-item>
      </el-form>

      <!--列表  @row-click="rowClick"-->
      <el-table :data="listData" border ref="tableTree" :header-cell-class-name="cellClass" @selection-change="handleSelectionChange">
        <el-table-column prop="dictionaryId" label="dictionaryId" type="selection" fixed></el-table-column>
        <el-table-tree-column
          fixed
          file-icon="icon icon-file"
          folder-icon="icon icon-folder"
          prop="cnName"
          label="名称"
          child-key="children"
          parentKey="parentId"
          :show-overflow-tooltip="true"
          treeKey="dictionaryId"
          levelKey="level"
          :indent-size="20"
        >
        </el-table-tree-column>
        <el-table-column prop="dictionaryId" label="编号"></el-table-column>
        <el-table-column prop="operation" label="操作">
          <template slot-scope="scope">
            <el-button size="small" @click="showAddEditDialog(scope.row)">编辑</el-button>
            <el-button type="danger" size="small" @click="delDictionary(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-col>
    <!-- 新增编辑 -->
    <el-dialog :title="addEditTitle" :visible.sync="addEditFormVisible" :close-on-click-modal="false">
      <el-form :model="addEditForm" label-width="120px" :rules="addEditFormRules" ref="addEditForm">
        <el-form-item label="上级分类名称" :hidden="addEditType == 'edit'">
          <el-input v-model="addEditForm.name" disabled auto-complete="off" size="small"></el-input>
        </el-form-item>
        <el-form-item label="字典名称" prop="cnName">
          <el-input v-model="addEditForm.cnName" auto-complete="off" size="small"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click.native="addEditFormVisible = false" size="small">取消</el-button>
        <el-button type="primary" @click.native="addEditSubmit" size="small">保存</el-button>
      </div>
    </el-dialog>
  </el-row>
</template>
<script>
import API from '@/api/system/api_dictionary'
import _ from 'lodash'
const searchForm = {
  cnName: null,
  dictionaryId: null
}
export default {
  data() {
    return {
      searchForm: _.cloneDeep(searchForm),
      listData: [],
      sels: [], //列表选中列
      loading: false,
      //编辑相关数据
      addEditTitle: '编辑',
      addEditType: 'edit',
      addEditFormVisible: false, //编辑界面是否显示
      addEditFormRules: {
        cnName: [
          { required: true, message: '请输入字典名称', trigger: 'blur' },
          { min: 1, max: 200, message: '长度在 1 到 200 个字符', trigger: 'blur' }
        ]
      },
      addEditForm: {
        dictionaryId: null,
        cnName: ''
      },

      multipleSelection: [],
      selectIndexArray: []
    }
  },
  mounted() {
    this.handleSearch()
  },
  methods: {
    rowClick(row) {
      this.$refs.tableTree.toggleRowSelection(row)
    },
    cellClass(row) {
      if (row.columnIndex === 0) {
        return 'disabledCheck'
      }
    },
    handleSelectionChange(val) {
      if (val && val.length > 0) {
        val.map((row, index) => {
          if (index === val.length - 1) return
          this.$refs.tableTree.toggleRowSelection(row)
        })
        this.multipleSelection = [val[val.length - 1]]
      } else {
        this.multipleSelection = []
      }
    },
    handleReset() {
      this.searchForm = _.cloneDeep(searchForm)
      this.handleSearch()
    },
    handleSearch() {
      let params = {
        ...this.searchForm
      }
      API.findList(params).then(result => {
        if (result && result.code === '000000') {
          this.listData = result.data
        }
      })
      this.multipleSelection = []
      this.selectIndexArray = []
    },
    //删除
    delDictionary(row) {
      if (row.children && row.children.length > 0) {
        this.$message.success({ showClose: true, message: '有子节点不能被删除', duration: 1500 })
        return
      }
      this.$confirm('确认删除该记录吗?', '提示', { type: 'warning' }).then(() => {
        API.remove(row.dictionaryId).then(result => {
          if (result && result.code === '000000') {
            this.$message.success({ showClose: true, message: '删除成功', duration: 1500 })
            this.handleSearch()
          }
        })
      })
    },
    //显示编辑界面
    showAddEditDialog(row) {
      if (this.$refs['addEditForm'] !== undefined) {
        this.$refs['addEditForm'].resetFields()
      }
      if (row) {
        this.addEditTitle = '编辑'
        this.addEditType = 'edit'
      } else {
        this.addEditTitle = '新增'
        this.addEditType = 'add'

        let selectLength = this.multipleSelection.length
        if (selectLength > 1) {
          this.$message.success({ showClose: true, message: '只能选择一个父节点', duration: 2000 })
          return
        }
        let isRoot = this.multipleSelection.length > 0
        row = { name: isRoot ? this.multipleSelection[0]['cnName'] : '根节点', parentId: isRoot ? this.multipleSelection[0]['dictionaryId'] : '0' }
      }
      this.addEditFormVisible = true
      this.addEditForm = Object.assign({}, row)
    },
    //编辑
    addEditSubmit() {
      this.$refs.addEditForm.validate(valid => {
        if (valid) {
          let para = Object.assign({}, this.addEditForm)
          delete para.children
          let response
          if (this.addEditType == 'add') {
            response = API.add(para)
          } else {
            response = API.update(para)
          }
          response.then(result => {
            this.addEditFormVisible = false
            if (result && result.code === '000000') {
              this.$message.success({ showClose: true, message: result.msg || '修改成功', duration: 2000 })
              this.handleSearch()
            } else {
              this.$message.error({ showClose: true, message: result.msg, duration: 2000 })
            }
          })
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
/* 去掉全选按钮 */
/deep/.el-table .disabledCheck .cell .el-checkbox {
  display: none !important;
}
</style>
