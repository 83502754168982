import * as http from '../http'

export default {
  //查询字典
  findList: params => {
    return http.post('/dictionary/list', params)
  },

  //新增字典
  add: params => {
    return http.post('/dictionary/add', params)
  },

  //更新字典
  update: params => {
    return http.post('/dictionary/update', params)
  },

  // 删除字典
  remove: id => {
    return http.post(`/dictionary/${id}`)
  },

  //分页列表
  page: params => {
    return http.post('/dictionary/page', params)
  }
}
